<template>
  <v-row>
    <v-col cols="12">
      <h3>Roommate{{ capacity > 2 ? 's' : '' }}</h3>
      <h4>Room capacity is {{ capacity }}<span v-if="capacity > 1">, so you need to select {{ capacity - 1 }} roommate. Click on a card below to change the roommate selection.</span></h4>
      <p v-if="capacity === 1">You have chosen a single-person room so roommate selection is unavailable. If you want to have a roommate, please choose a different room.</p>
    </v-col>
    <roommate v-for="(person, index) in roommates" :key="'col-' + index" :person="person" :sex="sex" :is-apartment="isApartment" :roommates="roommates" @update="(a) => update(a, index)"></roommate>
  </v-row>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    capacity: {
      type: Number,
      required: true
    },
    sex: {
      type: String,
      required: true
    },
    isApartment: {
      type: Boolean,
      default: false
    }
  },
  components: {
    roommate: () => import('./roommate')
  },
  setup (props, { root, emit }) {
    const roommates = ref(props.value)
    watch(() => props.value, () => {
      if (props.value.length > 0 && (props.value.length !== roommates.value.length || JSON.stringify(props.value) !== JSON.stringify(roommates.value.length))) {
        roommates.value = props.value
      }
    })

    watch(() => props.capacity, () => {
      if (roommates.value.length > props.capacity - 1) {
        roommates.value.splice(props.capacity - 1, roommates.value.length - (props.capacity - 2))
      } else if (roommates.value.length < props.capacity - 1) {
        while (roommates.value.length < props.capacity - 1) {
          roommates.value.push({ })
        }
      }
    })

    watch(roommates, () => {
      emit('input', roommates.value)
    })

    function update (a, index) {
      roommates.value.splice(index, 1, a)
    }
    return {
      roommates,
      update
    }
  }
}
</script>
